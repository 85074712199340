import React from "react"
import classNames from "classnames"

import * as styles from "./styles.module.scss"

export default function Box({
  children,
  className,
  withMinHeight,
  withTopLeftCorner,
}) {
  const classes = classNames(className, {
    [styles.box]: true,
    [styles.withTopLeftCorner]: Boolean(withTopLeftCorner),
    [styles.withMinHeight]: Boolean(withMinHeight),
  })

  return <div className={classes}>{children}</div>
}
