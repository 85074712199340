import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Accordion, { AccordionItem } from "/src/components/Accordion";
import * as styles from "../styles.module.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function FaqMa({
  groupId = "",
  hash
}) {
  const compareWithHash = id => hash.includes(id);

  return <Accordion size="md" groupId={groupId} className={`p-4 ${styles.faq}`}>
      <AccordionItem // #18
    groupId={groupId} id="what-is-m-and-a" open={compareWithHash("what-is-m-and-a")} heading="מה זה עסקת מיזוג ורכישה? (M&A)">
        <p>
          בקצרה - עסקאות מיזוג ורכישה (הידועות בתור M&A, שזה ראשי תיבות של
          Mergers and Acquisitions) הינן עסקאות שמהותן חיבור של יחידות עסקיות
          שונות לכדי יחידה אחת. עסקאות אלו יכולות להתבצע במגוון אופנים ולרוב
          יביאו לשינוי הבעלות על החברה הנרכשת. לעיתים, פעילות החברה הנרכשת תמוזג
          לתוך החברה הרוכשת.
        </p>
        <p>
          המוטיבציה של חברות לבצע רכישות יכולות מגוונות - למשל, לאפשר לחברה
          הרוכשת להפוך לבעלים של מוצרים או טכנולוגיות חדשים, להעניק לה גישה לכוח
          האדם והמומחיות של החברה הנרכשת וכו׳. במקרים רבים, החברה הרוכשת תרצה
          לשמר את העובדים של החברה הנרכשת, או חלק מהם, ולכן גם תציע להם תמריצים
          כספיים שיעודדו אותם להמשיך לעבוד בחברה (למשל, מניות של החברה הרוכשת).
        </p>
        <p>
          כאשר נעשית עסקה במסגרתה נמכרות המניות של החברה הנרכשת, התמורה לרכישה
          יכולה להיות במזומן, במניות של החברה הרוכשת או בשילוב של השניים.
        </p>
        <p>
          בעבר עיקר הרכישות של סטארטאפים בישראל היו על ידי חברות רב לאומיות,
          שלעיתים קרובות הקימו בעקבות הרכישה מרכז מחקר ופיתוח (R&D) מקומיים.
          בשנים האחרונות, כחלק מהצמיחה שמאפיינת את האקוסיסטם המקומי, גם חברות
          הייטק ישראליות בוגרות התחילו לרכוש סטארטאפים.
        </p>
      </AccordionItem>
      <AccordionItem // #19
    groupId={groupId} id="how-are-purchase-transactions-structured" open={compareWithHash("how-are-purchase-transactions-structured")} heading=" איך בנויות עסקאות רכישה ומה קורה בהן לאופציות?">
        <p>
          עסקאות מיזוג ורכישה יכולות להיבנות במגוון דרכים שישפיעו גם על התגמול
          של בעלי האופציות - כאלה שעובדים בחברה או כאלה שעבדו בה ומימשו את
          האופציות שלהם. חשוב לומר שמי שעזבו את החברה ולא מימשו את האופציות שלהם
          - לא יקבלו שום תגמול במקרה של עסקת רכישה או אירוע נזילות אחר.
        </p>
        <p>
          עבור רכישה של חברת סטארטאפ, החברה הרוכשת יכולה לשלם כסף (זו תהיה עסקת
          מזומן) או להעניק לבעלי המניות, ובהם גם העובדים והעובדים שמימשו את
          האופציות שלהם, מניות בחברה הרוכשת (זו תהיה עסקת מניות). לעיתים, בעסקת
          M&A יהיה שילוב של שני הגורמים.
        </p>
        <p>
          בעסקת מניות ישנו כמובן הבדל אם החברה הרוכשת היא ציבורית, וכך ניתן לממש
          את המניות שלה שערכן הוא ידוע (לרוב לאחר תקופת חסימה - בדומה לחסימה
          שלאחר הנפקה) בשוק ההון, או שהיא פרטית ולכן כל בעלי המניות צריכים
          להמתין לאירוע נזילות בחברה הרוכשת כדי שהמניות שלהם יהפכו לנזילות
          ויקבלו שווי לא רק "על הנייר".
        </p>
        <p>
          בסוף, החלק החשוב מבחינתכם הוא מה קורה לאופציות שלכם באקזיט וכמה הן
          שוות. ואכן, כעובדים בסטארטאפ לרוב, התגמול יקבע על פי החלק היחסי של
          האופציות שלכם שכבר הבשילו (vested), כולל הבשלה במסגרת תהליך אקסלרציה
          (ראו פרטים בסעיף בנושא אקסלרציה). כעובדים לשעבר שמימשו את האופציות
          שלהם, תקבלו תמורה לפי כמות המניות שאתם מחזיקים והתנאים שנקבעים בעסקה
          עבור כל מניה מהסוג שאתם הבעלים שלה.
        </p>
      </AccordionItem>
      <AccordionItem // #20
    groupId={groupId} id="steps-until-i-receive-the-money" open={compareWithHash("steps-until-i-receive-the-money")} heading=" מה השלבים עד שאקבל את הכסף בעקבות רכישה?">
        <p>
          במקרים רבים הפעם הראשונה בה יוודע לכם על עסקת רכישה תהיה כתבה בתקשורת
          - שתפורסם הרבה לפני שנחתמה העסקה בין הצדדים. קחו בחשבון שהמידע
          בפרסומים הללו עלול להיות נכון לשלב שבו עוד מתנהל משא ומתן בין החברה
          הרוכשת והנרכשת, ולכן לא סופי. לכן, רגע לפני שאתם הולכים לקנות יאכטה או
          לסגור משכנתא על בסיס הכסף שאתם צפויים לקבל בעקבות האקזיט - כדאי להמתין
          שהפרטים המלאים יתבררו והעסקה תסגר.{" "}
        </p>
        <h3>אז איך תדעו שאכן יש עסקת רכישה?</h3>
        <p>
          כאשר הרוכשת היא חברה ציבורית (נסחרת בבורסה) ועסקת הרכישה היא מהותית
          מבחינתה (כלומר בגודל משמעותי יחסית לפעילותה עסקית), יהיה דיווח לבורסה
          בנוגע לרכישה. אולם, במקרים רבים רכישת סטארטאפ אינה נחשבת מהותית מבחינת
          החברה הרוכשת ולכן לא בהכרח יהיה דיווח בנוגע לכך.
        </p>
        <p>
          במקרה שבשני הצדדים יש חברות פרטיות ואין להן אינטרס לפרסם את הרכישה -
          יתכן והעסקה לא תפורסם כלל באופן פומבי. בכלל, יש הבדל בין ההתנהלות של
          חברות שונות בתוך התהליך, וישנן חברות שיתנהלו בשקיפות וישתפו את העובדים
          בפרטי העסקה לאחר חתימתה, כולל הסברים על הנושאים הרלוונטיים להם.
        </p>
        <p>
          השלב הראשון בדרך לעסקת מיזוג או רכישה הוא חתימה על הסכם. השלמת העסקה
          (הביצוע שלה בפועל) מתבצעת בשלב שנקרא closing. השלמת העסקה כוללת את
          העברת התמורה המשולמת על מניות החברה הנרכשת. התמורה יכולה לכלול כסף או
          מניות, או שילוב ביניהם, בהתאם למבנה המשפטי של העסקה, ובעקרון היא אמורה
          להיות מועברת לבעלי המניות לאחר השלמת העסקה. אם בעסקה יש רכיב של מניות,
          במקרה שהרוכשת היא חברה פרטית, המניות של החברה הנרכשת יוחלפו במניות של
          החברה החדשה שגם הן אינן נסחרות. אם הרוכשת היא חברה ציבורית אז המניות
          המתקבלו בעסקה אמנם תהיינה מניות של חברה ציבורית, אך כמעט תמיד תחול
          עליהן תקופת חסימה (lockup) שרק אחריה תוכלו למכור את המניות של בשוק
          ההון.
        </p>
        <p>
          בין שני השלבים הללו (חתימה ו-closing) יכולים לעבור כמה חודשים. במהלך
          התקופה הזו תנאי העסקה יכולים להשתנות, למשל בעקבות דרישות רגולטוריות,
          אישורים הנדרשים לעסקה דוגמת הסכמה של ספק או לקוח מהותי או אישור של
          רשות התחרות. בנוסף, גם המחיר בעסקה עשוי להשתנות, אם הסכם המיזוג כולל
          מנגנון של שינוי מחיר בעקבות שינוי בעסקים של החברה הנמכרת או תנאים
          אחרים. לכם, כעובדים או עובדים לשעבר לא תהיה כמעט שקיפות בנוגע לתנאי
          העסקה שחשופים להנהלה החברה.
        </p>
        <p>
          לעיתים קרובות, בין החתימה ל-closing ישלחו מסמכים לחתימה על ידי כל בעלי
          המניות - כולל העובדים והעובדים לשעבר. המסמכים הללו יכולים לכלול פרטים
          חשובים ביחס למבנה העסקה, סוג התמורה, הסכומים שצפויים להתקבל, מועדי
          התשלום ועוד. חשוב שתעברו על המסמכים האלו ותבינו את המשמעות מבחינתכם,
          לרבות מה המשמעות אם אתם חותמים על המסמכים או לא ואם אתם מעבירים אותם
          בזמן הנדרש על ידי החברה.
        </p>
        <p>
          שימו לב: ברוב המקרים, גם אם מתבקשת הסכמתכם כבעלי מניות לעסקה, בפועל
          החברה יכולה לאשר את העסקה גם בלעדיה (בשל כמות המניות הקטנה יחסית
          המוחזקת על ידכם והוראות בעניין זה בתכנית האופציות של החברה). עם זאת,
          במקרים מסויימים, התוצאה של אי חתימה על המסמכים, או אי השלמה של הפרטים
          בזמן, עלולה לעכב את קבלת התמורה עבור המניות שלכם.
        </p>
        <p>
          ישנם הבדלים במידת השקיפות שנוקטות חברות שונות בנוגע להנגשת מידע
          לעובדים לגבי הרכישה. ישנן חברות ששולחות הסבר קצר וידידותי בנוגע
          למסמכים ואף קובעות מפגש לשאלות ותשובות בו הן מציגות את משמעות העסקה
          עבור העובדים, ובחברות פחות ידידותיות לעובדים אתם עלולים לקבל ערימת
          מסמכים ללא הסברים עליהם תדרשו לחתום תוך יומיים. ההמלצה שלנו - לנסות
          לקבל מהחברה הסבר קצר במילים פשוטות על מה אתם מתבקשים לחתום ומה
          המשמעויות.
        </p>
      </AccordionItem>
      <AccordionItem // #21
    groupId={groupId} id="get-the-money-after-purchase" open={compareWithHash("get-the-money-after-purchase")} heading=" מה עלי לעשות כדי לקבל את הכסף אחרי רכישה?">
        <p>לא הרבה, אבל חשוב לשים לב לפרטים.</p>
        <p>
          באירוע נזילות העובדים ובעלי המניות שמימשו את האופציות שלהם הם די
          פאסיביים. כלומר, לא תדרשו לקבל החלטה מתי לממש את האופציות שלכם כמו
          באירוע של הנפקה.
        </p>
        <p>
          בגדול, כעובדים בחברה בעלי אופציות או כעובדים לשעבר שמימשו את האופציות
          שלהם, תקבלו לאחר או במהלך הרכישה מסמכים המתארים את העסקה עליהם תתבקשו
          לחתום כדי לקבל את הכסף לכיסכם. שימו לב, <strong>חשוב</strong> להקפיד
          למלא את המסמכים על מנת שתקבלו את התגמול לחשבון הבנק שלכם ללא עיכובים
          מיותרים.
        </p>
        <p>
          ועוד טיפ קטן: שימו לב למטבע הרכישה - רוב עסקאות הרכישה מתבצעות
          בדולרים, אך ניתן לבקש מהגורם שמשלם (נאמן 102 או נאמן התשלומים בעסקה)
          לקבל את התמורה המגיעה לכם בשקלים או בדולרים - תבדקו ותחליטו מה עדיף
          מבחינתכם, לפי הנסיבות האישיות שלכם ולפי התנאים בבנק שלכם. הבחירה במטבע
          התשלום אמורה להופיע בטפסים שבהם אתם ממלאים את פרטי חשבון הבנק שלכם
          לצורך העברת התמורה.
        </p>
      </AccordionItem>
      <AccordionItem // #22
    groupId={groupId} id="when-will-i-be-notified" open={compareWithHash("when-will-i-be-notified")} heading=" מתי יודיעו לי על הרכישה?">
        <p>
          לפעמים ממש בתחילת הדרך, עם חתימת ההסכם, ולפעמים ממש בסוף, כאשר תתבקשו
          לחתום על מסמכים להעברת הכספים, לפעמים אפילו רק לאחר השלמת העסקה
          (ה-closing). המחויבות של החברה בהקשר הזה משתנה בהתאם להוראות הסכם
          הרכישה ומסמכי החברה עצמה. לעיתים, כאשר הרוכשת היא חברה ציבורית והודעה
          על רכישת חברה אחרת עשויה להשפיע על מחיר המניה הנסחרת, אסור להגיד
          לעובדים על הרכישה לפני סגירת העסקה על מנת שהמידע לא ידלוף. לעיתים
          קרובות, סגירת העסקה תהיה כמה חודשים לאחר הפרסום הראשוני בנוגע לעסקה
          בתקשורת.
        </p>
      </AccordionItem>
      <AccordionItem // #23
    groupId={groupId} id="what-will-be-my-profit" open={compareWithHash("what-will-be-my-profit")} heading=" מה יהיה הרווח שלי מרכישה של החברה?">
        <p>
          הרווח, או אופן חישוב הרווח שלכם, תלוי בעיקר במבנה העסקה, וכאמור, אין
          עסקת מיזוג ורכישה אחת זהה לאחרת ויש מגוון גדול של מבנים לעסקאות. חלק
          מהמורכבות נובע מהתמהיל שנקבע לרכיבי המזומן והמניות בכל עסקה.
        </p>
        <p>
          ננסה לפשט בכל זאת את האופן בו ניתן לנסות ולחשב את הרווח: בעסקת מזומן
          (או בחלק של העסקה שמורכב ממזומן) יקבע מחיר לכל מניה. יכולים להיות כמה
          סוגים של מניות שכל אחת תקבל תמורה שונה ויהיו בעלי מניות מסוימים,
          משקיעים בחברה המחזיקים סוג מניות מסוים, שיכולה להיות להם עדיפות
          וקדימות בקבלת התשלום. התמורה שתקבלו היא המחיר לסוג המניה שאתם מחזיקים
          (עבור עובדים אלו יהיו כמעט תמיד מניות רגילות - , common shares) כפול
          כמות המניות שאתם מחזיקים. במרבית המקרים העסקה תכלול בתנאים זהים למניות
          גם אופציות עובדים שהבשילו (או מלוא כמות האופציות, אם נקבע תהליך של
          האצה - "אקסלרציה" במקרה של עסקת רכישה).
        </p>
        <p>
          כדי לחשב את הרווח שלכם עליכם להחסיר את עלות מימוש האופציות מהתמורה
          שתקבלו - זהו הרווח לפני מס. על רווח זה תדרשו לשלם מס (לרוב, אם
          האופציות הוקצו תחת סעיף 102 - מס רווח הון בגובה 25%).
        </p>
        <p>
          בעסקת מניות יקבע יחס להמרה בין כל מניה בחברה הנרכשת למניות בחברה
          הרוכשת. גם במקרה זה כמות המניות החדשות שתקבלו תלויה בכמות המניות שאתם
          מחזיקים (האופציות שמימשתם כשעזבתם את החברה) או בכמות האופציות שלכם
          שהבשילו. במקרה הזה אתם עדיין לא יכולים לדעת מה יהיה הרווח שלכם, מאחר
          והמניות שלכם עוד לא קיבלו שווי כספי.
        </p>
        <p>
          שימו לב: גם אם קיבלתם בעסקה מניות של חברה ציבורית אשר יש להן שווי שוק
          ומחיר בבורסה, זה לא יהיה בהכרח המחיר במועד שתוכלו למכור את המניות.
          במקרים רבים תהיה תקופת חסימה - lockup - של כחצי שנה, והמחיר יכול
          להשתנות באופן משמעותי בתקופה הזו (כמובן - נקווה שהוא רק יעלה, אבל לא
          תמיד זה המצב).
        </p>
      </AccordionItem>
      <AccordionItem // #24
    groupId={groupId} id="will-i-certainly-make-money" open={compareWithHash("will-i-certainly-make-money")} heading=" האם בוודאות ארוויח כסף בעקבות רכישה?">
        <p>
          התשובה לשאלה זו עשויה להיות מפתיעה, אך בקצרה היא ׳לא׳. כך למשל, לפעמים
          החברה נמכרת ב״מכירת חיסול״ בסכום הנמוך מסך ההון שהושקע בה. במקרים אלו
          מחיר המניה ברכישה משקף שווי נמוך משווי המניה בזמן שקיבלתם את חבילת
          האופציות שלכם, כלומר - נמוך ממחיר המימוש של האופציות שלכם. במצבים כאלה
          לעיתים התשלום יחזור קודם כל למשקיעים שיקבלו עדיפות, ויכול להיות
          שהעובדים כלל לא יקבלו כסף (או - אם אתם מחזיקים מניות - יתכן שתקבלו
          סכום הנמוך מסכום המימוש ששילמתם).
        </p>
        <p>
          בנוסף, צריך לקחת בחשבון שלפעמים יש עדיפות לבעלי מניות אחרים שבמקרה של
          אקזיט יקבלו קדימות ותנאים מועדפים, בעיקר כאשר האקזיט הוא בשווי נמוך
          לשווי קודם של החברה. המניות שמוחזקות על ידי עובדים שמימשו אופציות הינן
          מניות רגילות (common stock) וקיימים משקיעים בחברה שקיבלו מניות המכונות
          "מניות בכורה" ( preferred stock), שכשמן כן הן - מועדפות או בעלות
          זכויות נוספות.
        </p>
        <p>
          המשמעות היא שבאירוע של אקזיט בעלי מניות אלה עשויים לקבל עדיפות מבחינת
          התשלום על פני בעלי מניות רגילות, כך שיתכן שבעלי מניות בכורה יקבלו
          תשלום מסוים למניה ובעלי מניות רגילות יקבלו סכום נמוך יותר, או במקרה
          הרע - לא יקבלו תמורה כלל. הדבר יכול לקרות גם באקזיטים "גדולים", בהתאם
          לתנאי המניות השונות בחברה.
        </p>
      </AccordionItem>
      <AccordionItem // #25
    groupId={groupId} id="what-information-will-the-company-share" open={compareWithHash("what-information-will-the-company-share")} heading=" איזה מידע החברה תשתף איתי לקראת הרכישה?">
        <p>
          היקף המידע בנוגע לעסקה והמועד בו תקבלו אותו, תלויים בעיקר בגישה של
          החברה ובמידת השקיפות שלה כלפי העובדים. כעובדים בחברה בהווה או בעבר
          שמימשו אופציות, אתם צפויים לקבל מסמכים לקראת שלב סגירת העסקה. לעיתים,
          אם אין צורך בהסכמת כל בעלי המניות, אתם יכולים לקבל הודעה בנושא רק לאחר
          סגירת העסקה. גם היקף המידע הנמסר משתנה בין החברות, ונע בין מידע מפורט
          וברור הכולל את מלוא תנאי העסקה עם הסבר לחלקים הרלוונטיים עבורכם ועד
          מידע מצומצם ביותר, שלעיתים אף לא יכלול במפורש את המחיר למניה או את
          תנאי העסקה העיקריים. אנחנו כמובן מאמינים שזו זכותכם שתהיה לכם שקיפות
          בנוגע למידע החשוב להבנת האופציות שהרווחתם בעבודה קשה.
        </p>
      </AccordionItem>
      <AccordionItem // #26
    groupId={groupId} id="case-of-an-exit" open={compareWithHash("case-of-an-exit")} heading=" במקרה של אקזיט האם יש הבדל בתגמול לעובדים בעבר או בהווה?">
        <p>
          עובדים שעזבו את הסטארטאפ ומימשו את האופציות שלהם הם בעלי מניות לכל דבר
          ועניין. ככאלו תהנו מתגמול עבור המניות שלכם באירוע נזילות. גם עובדים
          שעדיין מועסקים בחברה יוכלו לממש את האופציות שלהם שהבשילו למניות ולקבל
          תגמול במעמד הרכישה. במקרים מסוימים החברה הרוכשת תטיב עם מי שעדיין
          עובדים בחברה באמצעות הענקת חבילת שימור, שמבוססת על מניות בחברה הרוכשת,
          בונוסים על פי עמידה ביעדים וכו׳.
        </p>
        <p>
          אם אתם עובדים בחברה ויש לכם אופציות שהן unvested (כלומר עוד לא הבשילו)
          לחברה יש הרבה חופש להחליט מה לעשות איתן, למשל להאיץ את ההבשלה שלהן,
          להמיר אותן למניות החברה הרוכשת ועוד.
        </p>
      </AccordionItem>
      <AccordionItem // #27
    groupId={groupId} id="will-i-continue-to-make-money" open={compareWithHash("will-i-continue-to-make-money")} heading=" האם אמשיך להרוויח כסף אחרי הרכישה?">
        <p>
          לעיתים קרובות החברה הרוכשת תרצה לשמר את העובדים בסטארטאפ הנרכש כדי שלא
          יעזבו לאחר האקזיט. הרי היא ביצעה את הרכישה כדי שתוכל להינות מהיכולות
          של כוח האדם המצוין שפיתח את הפתרונות שנרכשו בעסקה. לכן, במקרים אלה
          החברה הרוכשת תייצר תוכנית לשימור העובדים שתכלול תוכנית אופציות, מניות
          או בונוס כספי על בסיס תנאים מוגדרים מראש.
        </p>
        <p>
          התוכנית הזו יכולה לשנות את מצב העניינים עבורכם כעובדים בסטארטאפ בזמן
          האקזיט - לפעמים חברה יכולה לרכוש סטארטאפ בסכום נמוך יחסית, כך שעבור
          האופציות המקוריות שלכם לא תקבלו תגמול משמעותי. אבל אתם עשויים להרוויח
          סכום משמעותי במסגרת תוכנית לשימור עובדים (כמובן - אם תישארו בחברה). גם
          בהקשר הזה, חשוב להבין את תנאי העסקה ולקבל תשובות מהחברה, על מנת שתוכלו
          לקבל את ההחלטות הנכונות עבורכם.
        </p>
      </AccordionItem>
    </Accordion>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      