import React from "react"

import * as styles from "./styles.module.scss"

export default function BtnClose({ onClick }) {
  return (
    <button className={styles.btnClose} onClick={onClick}>
      Close
    </button>
  )
}
