import React from "react"
import classNames from "classnames"

export function AccordionItem({ groupId = "", id, heading, open, children }) {
  return (
    <div className="accordion-item">
      <h3 className="accordion-header" id={id}>
        <button
          className={`accordion-button ${!open ? "collapsed" : ""}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#panelsCollapse-${id}`}
          aria-expanded="false"
          aria-controls={`panelsCollapse-${id}`}
        >
          <span className="fw-semibold">{heading}</span>
        </button>
      </h3>
      <div
        id={`panelsCollapse-${id}`}
        className={`accordion-collapse collapse ${open ? "show" : ""}`}
        aria-labelledby={id}
        data-bs-parent={groupId ? `#${groupId}` : ""}
      >
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  )
}

export default function Accordion({ className, size, groupId = "", children }) {
  const classes = classNames("accordion", className, {
    "accordion-md": size === "md",
  })

  return (
    <div className={classes} id={groupId}>
      {children}
    </div>
  )
}
