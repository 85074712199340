import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Accordion, { AccordionItem } from "/src/components/Accordion";
import * as styles from "../styles.module.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function FaqLiquidityEvent({
  groupId = "",
  hash
}) {
  const compareWithHash = id => hash.includes(id);

  return <Accordion size="md" groupId={groupId} className={`p-4 ${styles.faq}`}>
      <AccordionItem // #1
    groupId={groupId} id="what-is-an-ipo" open={compareWithHash("what-is-an-ipo")} heading="מה זה IPO?">
        <p>
          הנפקה ראשונה לציבור (או IPO שאלו ראשי התיבות של Initial Public
          Offering) היא תהליך בו חברה פרטית נרשמת לראשונה למסחר בבורסה ומאפשרת
          לציבור להתחיל לסחור במניותיה. תהליך ההנפקה כולל גם גיוס של הון שנכנס
          לקופת החברה והוא מגוייס מהציבור.
        </p>
        <p>
          הנפקה היא תהליך שנמשך לאורך תקופה וכולל מספר שלבים. לפני ההנפקה החברה
          נדרשת להגיש לרשות ניירות ערך הרלוונטית (בארה״ב ה-SEC) תשקיף ומסמכים
          נוספים בהם מפורטים נתונים אודות פעילותה, תוצאותיה הכספיות, כולל
          הכנסות, רווחים ומידע רב נוסף.
        </p>
        <p>
          לאחר ההנפקה החברה מחוייבת לדווח לציבור באופן שוטף על תוצאותיה הכספיות
          (לרוב מדי רבעון) וכן לדווח על אבני דרך משמעותיות ואירועים מהותיים
          בפעילותה.
        </p>
        <p>
          במסגרת ההנפקה החברה תהפוך לציבורית ובעלי המניות - בהם גם עובדים שמימשו
          אופציות שהבשילו - יוכלו למכור את המניות בשוק הציבורי (בבורסה) בתמורה
          למחיר המניה במועד המכירה. ברוב המקרים בעלי המניות בחברה יוכלו למכור את
          האחזקות שלהם רק לאחר תקופה בת מספר חודשים המכונה Lockup Period.
        </p>
        <p>
          בנוסף, יש חברות שאינן רושמות למסחר את כל המניות הקיימות שלהן במועד
          ההנפקה (הדבר אפשרי בבורסות בארה"ב אך לא בישראל) ותחלוף תקופת זמן נוספת
          עד להשלמת רישום המניות למסחר על ידי החברה באופן שניתן יהיה למכור אותן
          בבורסה. יש לכך השפעה על העובדים בחברות.
        </p>
      </AccordionItem>
      <AccordionItem // #2
    groupId={groupId} id="what-is-spac" open={compareWithHash("what-is-spac")} heading=" מה זה SPAC?">
        <p>
          אחת מצורות ההנפקה הפופולריות בשנים האחרונות היא באמצעות חברה למטרת
          רכישה מיוחדת או ״חברת צ׳ק פתוח״ הידועה בשם SPAC (Special Purpose
          Acquisition Company). בשנים 2020-2021 כמות עסקאות ה-SPAC הגיעה לשיא
          (ובתקופה האחרונה האופנה קצת מתקררת). חברות הייטק משתמשות באפיק זה בין
          היתר כי הוא מאפשר להן להרשם למסחר, להפוך לציבוריות ולאפשר לבעלי המניות
          אירוע נזילות - מבלי לעבור את ההליך הבירוקרטי הארוך והמורכב הכרוך
          ב-IPO.
        </p>
        <p>
          בקצרה, בעסקת SPAC פעילותה של חברה פרטית, כמו סטארטאפ, ממוזגת לתוך חברה
          ללא פעילות עסקית (שהיא למעשה שלד בורסאי) שגייסה הון ממשקיעים אך ורק על
          מנת לבצע את המיזוג. במסגרת התהליך החברה הממוזגת הופכת לציבורית ומתחיל
          מסחר במניות שלה בבורסה. לאחר מכן, בדומה למצב בהנפקות IPO, החברה
          מחוייבת בשקיפות ובדיווח של התוצאות הרבעוניות שלה ואבני דרך חשובות
          אחרות.
        </p>
      </AccordionItem>
      <AccordionItem // #3
    groupId={groupId} id="difference-between-ipo-and-spac" open={compareWithHash("difference-between-ipo-and-spac")} heading=" מה ההבדל בין IPO ל-SPAC?">
        <p>
          בסופם של שני התהליכים - IPO ו-SPAC - הסטארטאפים יהפכו מחברות פרטיות
          לחברות ציבוריות שבעלי המניות שלהן, כולל עובדים ועובדים בעבר שמימשו את
          האופציות שלהם, יוכלו למכור את המניות שלהם בבורסה. ההבדל בין שני
          התהליכים הוא בדרך שמביאה את הסטארטאפ עד לשערי הבורסה.
        </p>
        <p>
          בתהליך IPO חברה בעלת פעילות עסקית מציעה את המניות שלה למשקיעים בשוק
          ובתמורה מגייסת הון לקופתה. החברה מגייסת הון מכמות גדולה של משקיעים
          והצלחתה או אי הצלחתה בגיוס תלויה בהעדפות המשקיעים בשוק הציבורי. כמו
          כן, לקראת IPO החברה שעומדת לפני הנפקה נדרשת לקבל אישור של רשות ניירות
          ערך לתשקיף שלה (המסמך הפומבי שעל בסיסו מבוצעת ההנפקה, הכולל מידע רב על
          החברה). זהו תהליך ארוך ומורכב יחסית במהלכו החברה עשויה לקבל דרישות
          והערות שונות מהרשות.
        </p>
        <p>
          לעומת זאת, בעסקת SPAC חברת ה״צ׳ק פתוח״ כבר גייסה הון ומניותיה נסחרות
          בבורסה, וכעת היא צריכה לבחור מי תהיה חברת המטרה אותה היא תמזג לתוך
          פעילותה. כך שלמעשה עיקרו של הליך זה הוא משא ומתן על הסכם המיזוג בין
          חברת המטרה לחברת ה- SPAC. בהשוואה ל-IPO זהו תהליך זול ופשוט יחסית
          שכרוך בפחות הוצאות על ייעוץ משפטי ופיננסי. כמו כן, שווי החברה המתמזגת
          (הסטארטאפ) ומחיר המניה ההתחלתי שלה בהליך זה נקבע כאמור במשא ומתן בין
          הצדדים, ולא בהכרח על בסיס הערכות המשקיעים בשוק.
        </p>
        <h3>מה ההבדל מבחינת העובדים?</h3>
        <p>
          לרוב השלמת תהליך ה-SPAC תהיה מהירה יותר מ-IPO (אך לא תמיד. לעיתים
          במיזוג ה- SPAC נקבעים תנאים מסוימים אשר השגתם אורכת זמן). כמו כן, לרוב
          ב-SPAC יהיה תהליך פיצול או מיזוג של המניות (Split או Reverse Split),
          זאת אומרת שמניה אחת בחברה בה אתם עובדים לא תהפוך בהכרח למניה אחת בחברה
          הממוזגת לאחר ה-SPAC, וכמות המניות שתחזיקו תיקבע לפי היחס שייקבע במסגרת
          המיזוג. לכן, אל תיבהלו אם אתם רואים שהמחיר למניה שפרסמה החברה הוא למשל
          $10 למניה, כאשר חשבתם לפני כן שמחיר המניה שלכם הוא $100 למניה - בידקו
          האם היה ספליט במניה ובאיזה יחס, יתכן שכל מניה שלכם פוצלה ל- 10 מניות
          בחברה הממוזגת.
        </p>
        <p>
          בשורה התחתונה, מבחינתך כעובד.ת אין הבדל משמעותי ומה שמשנה הוא שבסוף
          המניות של החברה יהיו רשומות למסחר וניתן יהיה למכור אותן.
        </p>
      </AccordionItem>
      <AccordionItem // #4
    groupId={groupId} id="difference-between-an-ipo-in-israel-and-abroad" open={compareWithHash("difference-between-an-ipo-in-israel-and-abroad")} heading=" האם יש הבדל בין הנפקה של חברה בישראל ובחו״ל?">
        <p>
          ישנם כמה הבדלים בין הנפקות של חברות בישראל ובארה״ב שנובעים משוני
          ברגולציה הקיימת בכל אחד מהשווקים. הנה כמה מההבדלים המהותיים לעובדי
          סטארטאפים בישראל:
        </p>
        <ol>
          <li>
            <p>
              תקופת חסימה (Lockup period) - בהנפקה בבורסות האמריקאיות (נאסד״ק או
              בורסת ניו יורק) בדרך כלל יש סטנדרט בשוק שנקבע על בסיס הסכמה עם
              החתמים או הסכמה עם חברת ה- SPAC בנוגע למשך התקופה בה לא ניתן למכור
              את המניות לאחר ההנפקה. הסטנדט בשוק לאחרונה הוא תקופת חסימה של חצי
              שנה, כאשר לעיתים משחררים מהחסימה חלק מהמניות (15% - 25%) מוקדם
              יותר, כ- 3 חודשים לאחר ההנפקה, או כתלות בהתקיימות תנאים מסוימים.
            </p>
            <p>
              לעומת זאת, בישראל ישנן תקנות של הבורסה בנוגע למועדים בהן בעלי
              המניות השונים יכולים למכור אותן. יכולים להיות מצבים בהם לפי תקנות
              הבורסה בעלי מניות מסוימים לא יהיו כפופים לתקופת חסימה בכלל (למשל,
              עובדים קיימים של החברה שאינם נושאי משרה ואינם מחזיקים אחוז משמעותי
              בחברה). לחילופין, לעיתים עובדים או עובדים לשעבר בחברה או בעלי
              תפקידים בכירים יכולים להיות כפופים לתקנות מחמירות שיאפשרו להם
              למכור את המניות שלהם לאחר ההנפקה בקצב איטי (3 חודשים חסימה מוחלטת
              ולאחר מכן אפשרות למכירה של 2.5% בכל חודש למשך 15 חודשים נוספים, או
              12.5% בכל חודש למשך 6 חודשים נוספים, תלוי בסטטוס של בעל המניות).
              גם כאן, חברה יכולה לקבוע הוראות חסימה נוספות (מחמירות יותר מתקנות
              הבורסה) אשר יחולו על המניות לאחר ההנפקה.
            </p>
          </li>
          <li>
            <p>
              מיסוי - לעובדים ישראלים הנמצאים בישראל וחייבים במס בישראל בלבד, לא
              יהיה הבדל במיסוי בין הנפקה בארה״ב ובישראל ביחס לאופציות שניתנו תחת
              סעיף 102 לפקודת מס הכנסה.
            </p>
          </li>
          <li>
            <p>
              שפה - התשקיפים והמסמכים הרלבנטיים לקראת הנפקה בישראל יפורסמו
              בעברית, לעומת הבורסות האמריקאיות ששם כמובן יפורסמו באנגלית.
            </p>
          </li>
          <li>
            <p>
              תהליך - ישנם כמה הבדלים בתהליכים עצמם. כך למשל בישראל חברה חייבת
              לרשום למסחר את כל המניות שלה. בארה״ב לעומת זאת אפשר לרשום למסחר רק
              חלק מהמניות וזה עשוי להשפיע על העובדים. כמו כן, בישראל לא קיים
              תהליך מוסדר של SPAC כמו זה שבארה״ב.
            </p>
          </li>
        </ol>
      </AccordionItem>
      <AccordionItem // #5
    groupId={groupId} id="stages-until-the-completion-of-the-spac" open={compareWithHash("stages-until-the-completion-of-the-spac")} heading=" מהם שלבי התהליך עד להשלמת ה-SPAC שחשוב להכיר?">
        <p>
          בדרך כלל השלב הראשון המפורסם לציבור ולכם כעובדים בדרך של החברה ל-SPAC
          הוא דיווח פומבי והודעה לתקשורת על הסכם מיזוג שנחתם בין חברת ה-SPAC
          לחברה שיש לה פעילות עסקית. ההודעה תכלול לרוב את תנאי העסקה, כולל שווי
          המניה המיועד, תנאים רגולטוריים שצריכים להתקיים ואת לוחות הזמנים
          המשוערים להשלמת העסקה. עם זאת, בשלב זה עוד לא תוכלו לדעת בדרך כלל מה
          יהיה השווי המדויק של חבילת האופציות שלכם כי הוא יהיה תלוי בגורמים
          שייקבעו סופית בסמוך למועד המיזוג הסופי, כגון שיעור ה-Split במניה
          והשווי המדויק של החברות המתמזגות.
        </p>
        <p>
          לאחר מכן, לרוב יש תקופה בה החברות המתמזגות פועלות להשלמת תנאים שנקבעו
          צורך המיזוג (כגון אישור של רגולטורים בתחום הפעילות של החברה ואישור של
          בעלי מניות או ספקים ולקוחות מהותיים). לעיתים יפורסמו דיווחים של חברת
          ה- SPAC על השגת תנאים אלו, אך לא תמיד.
        </p>
        <p>
          לבסוף, אסיפת בעלי המניות של חברת ה-SPAC צריכה להתכנס ולאשר את המיזוג
          ואת תנאיו. מועד האסיפה יפורסם ותוכלו לעקוב אחריו ולקראתו יהיו פרסומים
          רלבנטיים. לעיתים נעשים שינויים בתנאי המיזוג בשלב זה, בעקבות דרישת בעלי
          מניות וכדי להשיג את הרוב הנדרש בהצבעה. לאחר שניתן אישור אסיפת בעלי
          המניות המיזוג מבוצע, ובעלי המניות מקבלים מניות חדשות בחברת הממוזגת
          הנסחרת.
        </p>
        <p>
          כאמור, לרוב לאחר ההנפקה ישנה תקופת חסימה, בה בעלי המניות, ובהם עובדים
          ועובדים לשעבר, לא יכולים למכור את האופציות שלהם (מידע נוסף בסעיף
          בנושא).
        </p>
        <p>
          בהשוואה להנפקת IPO, ה-SPAC יכול להיות תהליך קצר יחסית שנמשך חודשים
          ספורים אך עלול להתארך עקב קשיים בהשגת תנאי המיזוג או קשיים בהשגת
          הסכמות בעלי המניות של ה- SPAC.
        </p>
      </AccordionItem>
      <AccordionItem // #6
    groupId={groupId} id="what-will-happen-to-my-options" open={compareWithHash("what-will-happen-to-my-options")} heading="מה יקרה לאופציות שלי בעקבות הנפקת החברה והפיכתה לציבורית?">
        <p>
          בדרך כלל, אופציות שקיבלתם בסטארטאפ והן עדיין בתוקף, יומרו לאופציות של
          החברה הציבורית, כלומר - אופציות שניתנות למימוש למניות של החברה
          הציבורית. לעיתים זה יהיה במסגרת תכנית אופציות חדשה של החברה - הדבר
          תלוי באופן הפיכת החברה לציבורית (הנפקה, מיזוג או דרך אחרת) וכן בהחלטות
          של החברה ובשיקולי המס שלה.
        </p>
        <p>
          שימו לב, לפני ההנפקה עשוי להיות תהליך של פיצול או איחוד של המניות
          שישנה את כמות המניות של החברה. כלומר, לא בהכרח אופציה אחת בחברה הפרטית
          תומר לאופציה אחת בחברה הציבורית.
        </p>
        <p>
          המניות שלכם - אם מימשתם אופציות כשעזבתם את החברה או שתחליטו לממש לקראת
          ההנפקה - הופכות בעקבות הנפקת החברה למניות סחירות ויש לכם אפשרות למכור
          אותן. שימו לב, שלעיתים קרובות יש לאחר ההנפקה תקופת חסימה (lock-up
          period), במהלך תקופה זו בעלי המניות בחברה לא יכולים למכור אותן. לכן,
          יכול לחלוף פרק זמן עד שתוכלו למכור את המניות שלכם לאחר הנפקה ולהפגש עם
          הכסף המיוחל. בסעיף במדריך זה בנושא תקופת החסימה תמצאו מידע נוסף בנושא.
        </p>
        <p>
          חשוב לומר שאם עזבתם את החברה ולא מימשתם את האופציות שלכם - לא תקבלו
          שום תגמול במקרה של הנפקת החברה או אירוע נזילות אחר.
        </p>
      </AccordionItem>
      <AccordionItem // #7
    groupId={groupId} id="what-are-the-steps" open={compareWithHash("what-are-the-steps")} heading="מה הם השלבים עד להשלמת ה-IPO?">
        <p>
          הנפקה ראשונה לציבור (IPO) היא תהליך ארוך יחסית שהשלמתו נמשכת לאורך
          תקופה. בשלב הראשון החברה תגיש טיוטה לתשקיף לרשות ניירות ערך הרלבנטית.
          התשקיף יכלול מידע על החברה, פעילותה, הכנסותיה, מידע בנוגע למגבלות על
          המניות ועוד. לפעמים, בעקבות הערות של הרשויות הרלבנטיות או משוב מחתמים,
          החברה תגיש כמה טיוטות ותערוך בהן שינויים. שימו לב, הגשת טיוטת התשקיף
          לא מחייבת שהחברה תשלים את ההנפקה.
        </p>
        <p>
          לאחר מכן החברה תצא ל-Road Show ותפגוש במהלכו משקיעים. כשבוע לפני
          ההנפקה יפורסם הטווח למחיר המניה בהנפקה שיקבע את השווי שלה. בסופו של
          התהליך נערך Pricing בו נקבע המחיר הסופי של המניה בהנפקה - המחיר בו היא
          תחל להסחר.
        </p>
        <p>לאחר ההנפקה החברה נרשמת למסחר וניתן לסחור במניות שלה.</p>
        <p>
          כאמור, לרוב לאחר ההנפקה ישנה תקופת חסימה בה בעלי המניות, ובהם גם אתם
          כעובדים ועובדות בהווה ולשעבר, לא יכולים למכור את המניות שלהם.
        </p>
      </AccordionItem>
      <AccordionItem // #8
    groupId={groupId} id="what-information-will-i-receive" open={compareWithHash("what-information-will-i-receive")} heading="איזה מידע אקבל מהחברה לקראת ההנפקה?">
        <p>
          באופן פורמלי, החברות לא מחוייבות לשתף איתכם מידע כעובדים ועובדות
          (בהווה ולשעבר) לקראת הנפקות. המידע הרלבנטי יופיע בטיוטות התשקיף,
          בתשקיף ובדיווחים אחרים של החברה לבורסה שנגישים לציבור. חשוב להגיד,
          תשקיף הוא מסמך מורכב מאוד לקריאה והבנה, גם לאנשי מקצוע, ובעלי מניות או
          עובדים עשויים להיתקל בקשיים כשינסו לאתר בו או להסיק ממנו את המידע
          שמעניין אותם (כגון - כמות המניות או האופציות שהם צפויים להחזיק לאחר
          ההנפקה).
        </p>
        <p>
          ישנן חברות שמנגישות את המידע לעובדים והעובדות בצורה פשוטה ובהירה
          ודואגות לעדכן את העובדים לקראת תאריכים חשובים שצריך להערך אליהם. אנחנו
          מאמינים שזו זכותכם שתהיה לכם שקיפות ונגישות למידע בנוגע לאופציות שלכם.
        </p>
        <p>
          לעיתים, אם ההנפקה כוללת שינויים בתקנון או במבנה ההון של החברה אשר
          מצריכים את הסכמת כל בעלי המניות או בעלי האופציות, ישלחו לכם העובדים
          מסמכים לחתום עליהם. עם זאת, מסמכים אלו לא בהכרח יכללו את המידע שמעניין
          אתכם ובנוסף, לרוב יש לחברות ייפוי כח לחתום בשם העובדים והעובדים לשעבר.
        </p>
      </AccordionItem>
      <AccordionItem // #9
    groupId={groupId} id="how-much-will-i-earn" open={compareWithHash("how-much-will-i-earn")} heading="כמה ארוויח בעקבות ההנפקה של החברה?">
        <p>
          הרווח שלכם בעקבות ההנפקה יקבע על פי מחיר המניה במועד בו תמכרו את
          המניות שלכם. לרוב, בתקופה הראשונה לאחר ההנפקה תהיו מנועים מלמכור את
          המניות בגלל תקופת החסימה (lock-up period) ואחריה תוכלו לבחור מתי
          התזמון המתאים עבורכם למכור את המניות על פי מגוון שיקולים.
        </p>
        <p>
          הרווח שלכם ממכירת המניות יהיה הפער בין מחיר מכירת המניה (בהתאם למחיר
          המניה בשוק הציבורי במועד בו תמכרו אותה) לבין מחיר המימוש למניה שנקבע
          בחבילת האופציות שהוענקה לכם.
        </p>
        <p>
          במקרים רבים, אם אתם מממשים אופציות למניות כאשר החברה כבר ציבורית, לא
          תדרשו לשלם מכיסכם את מחיר המימוש, מאחר והוא ינוכה ממחיר המניה שיועבר
          אליכם.
        </p>
        <p>
          כמות האופציות שתוכלו לממש למניות בהנפקה תלויה בכמות האופציות שלכם
          שהבשילו (vested) עד מועד ההנפקה. לעומת זאת, אם עזבתם את הסטארטאפ לפני
          ההנפקה ומימשתם את האופציות שלכם, הפכתם בעלי מניות בחברה אז כבר שילמתם
          את מחיר המימוש של האופציות.
        </p>
        <p>
          שימו לב: הרווח שלכם תלוי גם במיסוי על הרווח שלכם ממכירת המניות. על פי
          סעיף 102 לפקודת מס הכנסה ברוב המקרים עובדים בסטארטאפים ישלמו מס רווח
          הון בגובה 25% מהרווח הנוצר ממכירת המניות (אחרי שהחברה הופכת לציבורית
          יכול להיות שחלק מהרווח שיהיה כפוף לשיעור מס גבוה יותר, כמו המס על
          המשכורת שלכם).
        </p>
      </AccordionItem>
      <AccordionItem // #10
    groupId={groupId} id="will-i-for-sure-make-money" open={compareWithHash("will-i-for-sure-make-money")} heading="האם בוודאות ארוויח כסף בעקבות הנפקה?">
        <p>התשובה לשאלה זו עשויה להיות מפתיעה, אך בקצרה היא ׳לא׳.</p>
        <p>
          כך למשל, אם מחיר המניה לאחר ההנפקה נמוך ממחיר מימוש האופציות שלכם - לא
          תרוויחו ממכירת המניות בשוק ההון במועד זה. אם תחליטו להמתין עם מכירת
          המניות למועד מאוחר יותר, ובינתיים מחיר המניה בשוק הציבורי יעלה - תוכלו
          להרוויח ממכירה של המניות במועד מאוחר יותר.
        </p>
        <p>
          חשוב לזכור שמחיר המניה בשוק ההון יכול לעלות או לרדת בתקופות שונות
          וההחלטה בדבר מועד מכירת המניות תהיה תלויה בצורך האישי שלכם וכן בהערכה
          האישית שלכם ביחס למחיר המניה בעתיד.
        </p>
        <p>
          צריך לקחת בחשבון שגם אם שווי החברה בהנפקה גבוה משווי החברה שלפיו
          קיבלתם את האופציות שלכם (ולפיו נקבע מחיר המימוש של האופציות), לפעמים
          יש עדיפות לבעלי מניות המחזיקים סוגי מניות מסוימים (עדיפות שנקבעה
          בהסכמי ההשקעה של החברה כשהייתה פרטית) שבמקרה של אקזיט יקבלו קדימות
          מבחינת חלוקה ותנאים מועדפים.
        </p>
        <p>
          דבר אחרון שצריך לקחת בחשבון הוא תשלום מס שישפיע על התקבולים שלכם. על
          פי סעיף 102 לפקודת מס הכנסה ברוב המקרים עובדים ישלמו מס רווח הון בגובה
          25% מהרווח הנוצר ממכירת המניות.
        </p>
      </AccordionItem>
      <AccordionItem // #11
    groupId={groupId} id="option-maturation" open={compareWithHash("option-maturation")} heading=" מה זה אומר תהליך האצה (אקסלרציה) של הבשלת אופציות?">
        <p>
          שאלה זו רלבנטית עבור עובדים שעדיין מועסקים בחברה בזמן אירוע הנזילות.
          באופן עקרוני, תקבלו תגמול רק עבור האופציות שלכם שכבר הבשילו (vested)
          במועד אירוע הנזילות. תהליך האצה (אקסלרציה), יכול לקבוע שחלק מסויים
          מהאופציות שלכם יבשיל באופן מיידי בעקבות אירוע הנזילות בסטארטאפ.
        </p>
        <p>
          חשוב לציין שאקסלרציה היא אירוע נדיר שכמעט ואינו קורה, זהו לא הסטנדרט
          המקובל בתעשייה. בכל מקרה, אתם יכולים לדעת האם אתם צפויים להינות
          מאקסלרציה מעיון בהסכם מענק האופציות שלכם (המסמך יכול להקרא Notice of
          grant או Options Award) - אם אתם זכאים לאקסלרציה זה יכתב שם.
        </p>
        <p>
          שווה לציין שברוב החברות מוענקת אקסלרציה בנסיבות שמוגדרות בהסכם רק
          לעובדים בכירים מאוד, ולאחר שהתקיים משא ומתן בנוגע לכך. אם אתם במעמד
          הזה, מומלץ לוודא שסעיף האקסלרציה יהיה רחב ככל שניתן ויכסה טווח רחב ככל
          האפשר של אירועי החלפת מניות, שינוי שליטה בחברה, הנפקה וכיו"ב.
        </p>
        <p>
          לעיתים, בעסקאות רכישה (M&A) לחברה יש גמישות גדולה יותר, והיא עשויה
          לדאוג שעובדי החברה יקבלו אקסלרציה חלקית או מלאה של האופציות במסגרת
          המו״מ עם החברה הרוכשת. אולם, זהו תהליך שאי אפשר לדעת בוודאות שהוא עומד
          להתקיים ואתם לא יכולים להסתמך עליו. בנוסף, שימו לב שאקסלרציה של
          האופציות שאינה קבועה מראש במענק האופציות עשויה לשנות את המיסוי על
          האופציות ולעיתים תהיה תלויה בקבלת רולינג ספציפי מרשות המסים.
        </p>
      </AccordionItem>
      <AccordionItem // #12
    groupId={groupId} id="compensation-who-left-the-company" open={compareWithHash("compensation-who-left-the-company")} heading="האם יש הבדל בתגמול שיקבלו עובדים שעזבו והאם יש הבדל בתגמול שיקבלו עובדים שעזבו את החברה ומימשו את האופציות שלהם למי שעדיין עובדים בחברה בזמן האקזיט?">
        <p>
          עובדים שעזבו את הסטארטאפ ומימשו את האופציות שלהם הם בעלי מניות לכל דבר
          ועניין. אם זה המצב שלכם - תהנו מתגמול עבור המניות שלכם בעקבות ההנפקה
          כשתמכרו אותן בשוק ההון או במעמד רכישה של החברה.
        </p>
        <p>
          בדומה לעובדים שעזבו, גם עובדים שעדיין מועסקים בחברה יוכלו לממש את
          האופציות שלהם שהבשילו למניות ולקבל תגמול כאשר ימכרו את המניות. עובדים
          שעדיין מועסקים בחברה עשויים לקבל לאחר ההנפקה חבילות חדשות של אופציות
          או מניות בחברה הנסחרת, שגם הן יבשילו לאורך זמן בדומה לאופציות. המטרה
          של חבילות אלה היא להמשיך ולשמר את העובדים אחרי ההנפקה (ואחרי שכבר נהנו
          מאירוע נזילות אחד) ולהגביר את המוטיבציה שלהם.
        </p>
      </AccordionItem>
      <AccordionItem // #13
    groupId={groupId} id="when-will-i-receive-the-money" open={compareWithHash("when-will-i-receive-the-money")} heading="מתי אקבל את הכסף בעקבות ההנפקה?">
        <p>
          במידה רבה המועד בו תקבלו את הכסף תלוי בכם - או ליתר דיוק במועד בו
          תחליטו למכור את המניות שלכם בחברה שאחרי ההנפקה היא ציבורית. ברוב
          המקרים בתקופה שמיד אחרי ההנפקה שמוגדרת Lock-up Period לא תוכלו למכור
          את המניות שלכם בחברה. אורך התקופה משתנה מחברה לחברה ויהיה בדרך כלל
          כ-180 יום בחברות אמריקאיות. לאחר מכן תוכלו להחליט האם למכור את המניות
          באופן מיידי או להמתין עם המכירה מתוך אמונה שמחיר המניה יעלה עם הזמן
          וכך גם פוטנציאל הרווח שלכם. חשוב לזכור שמחיר המניה עלול גם לרדת והחלטת
          המכירה תהיה מבוססת, בין היתר, על ההערכות שלכם ביחס למחיר המניה בעתיד.
        </p>
      </AccordionItem>
      <AccordionItem // #14
    groupId={groupId} id="what-is-lockup" open={compareWithHash("what-is-lockup")} heading="מה זה Lockup ומה יקרה בסופו?">
        <p>
          תקופת חסימה, או Lock-up Period, היא פרק הזמן מיד אחרי ההנפקה במהלכו
          בעלי המניות מנועים מלמכור את המניות שלהם. אורך התקופה משתנה מחברה
          לחברה ויהיה בדרך כלל בחברות אמריקאיות כ-180 יום. לאחר מכן תוכלו להחליט
          כבעלי ובעלות מניות האם למכור את המניות באופן מיידי או להמתין עם המכירה
          מתוך אמונה שמחיר המניה יעלה עם הזמן וכך גם פוטנציאל הרווח שלכם. לכל
          אחד ואחת ישנם שיקולים ואילוצים שונים המשפיעים על מועד המכירה.
        </p>
        <p>
          תקופת החסימה בה לא ניתן למכור את המניות תקפה לכלל בעלי המניות בהם
          משקיעים, דרג ניהולי, עובדים ועובדי עבר שמימשו את האופציות. לעובדים
          בכירים בחברה או משקיעים מהותיים עשויות לעיתים להיקבע גם תקופות חסימה
          ארוכות יותר.
        </p>
      </AccordionItem>
      <AccordionItem // #15
    groupId={groupId} id="how-are-the-shares-sold" open={compareWithHash("how-are-the-shares-sold")} heading="איך מוכרים את המניות אחרי תום תקופת ה-lockup?">
        <p>
          במידה וכבר מימשתם את האופציות שלכם למניות, למשל על רקע עזיבת הסטארטאפ,
          תוכלו לבצע את המכירה שלהן דרך נאמן 102 או באמצעות פלטפורמת מסחר שהחברה
          תרשום בה את מניות העובדים. אם אתם עדיין עובדים בחברה ויש לכם אופציות
          שהבשילו ולא מימשתם, תצטרכו לממש את האופציות כדי למכור את המניות (לרוב
          בחברה ציבורית ניתן לבצע את המימוש תוך כדי המכירה או אפילו כתלות בהשלמת
          המכירה של המניות).
        </p>
        <p>
          שימו לב, שמכירת המניות מהווה אירוע מס - זהו המועד בו תדרשו לשלם את המס
          בגין המניות (ראו את השאלה בנושא).
        </p>
      </AccordionItem>
      <AccordionItem // #17
    groupId={groupId} id="how-many-taxes-will-i-pay" open={compareWithHash("how-many-taxes-will-i-pay")} heading="כמה מיסים אשלם בעקבות אירוע הנזילות בחברה?">
        <p>
          סעיף 102 לפקודת מס הכנסה קובע תנאים ״ידידותיים״ לתשלום מס לעובדים בעלי
          אופציות לפיהם העובדים ישלמו מס רווח הון בגובה 25% (ולא מס הכנסה) ורק
          בעת מכירת המניות (ולא בעת הקצאת האופציות או מימוש האופציות למניות).
          כלומר, תדרשו לשלם מס רק לאחר שתמכרו את המניות שלכם, בשוק ההון או בעסקת
          מכירה פרטית.
        </p>
        <p>
          סעיף 102 הוא הטבה משמעותית לעובדים בסטארטאפים שלרוב שיעור המס השולי
          שהם משלמים על ההכנסה שלהם גבוה יותר מ-25%. כדי להינות מהטבה זו, עליכם
          לעמוד במספר תנאים כעובדי ועובדות החברה, כולל החזקת האופציות (או המניות
          שמומשו מהאופציות) אצל נאמן במשך שנתיים לפחות ממועד הענקת האופציות. אם
          לא תעמדו בתנאים הללו, תאלצו לשלם מס פירותי גבוה יותר. כך למשל, אם
          החברה מגיעה להנפקה או ל-SPAC תוך פחות משנתיים ממועד ההענקה של האופציות
          שלכם, והאופציות או המניות שמימשתם יומרו באופציות או מניות של החברה
          הציבורית, עדיין לא תידרשו לשלם מס כל עוד לא תמכרו את המניות (כי הן
          עדיין יוחזקו אצל הנאמן).
        </p>
        <p>
          בעסקת של מכירת המניות בשוק הפרטי, בין אם עסקת סקנדרי (Secondary) בה
          נמכרות חלק ממניות החברה או עסקת מכירה של החברה כולה (M&A) סוגיית
          המיסוי עשויה להיות יותר מורכבת. יכול להיווצר מצב בו החברה כולה נמכרת
          ולמעשה "מאלצים" את העובדים שמחזיקים אופציות או מניות למכור את המניות
          שלהם, גם אם עדיין לא חלפה תקופת השנתיים ממועד הענקת האופציות. במקרה
          כזה העובדים יהיו חשופים לשיעור מס גבוה יותר, למרות שמכירת המניות לא
          היתה בהכרח לפי בחירתם. אבל, מרבית החברות ידאגו לפתרון לבעיה זו ויפנו
          לקבל אישור מרשות המיסים (ruling) לפיו העובדים עדיין יוכלו להנות ממס
          מופחת לפי סעיף 102, בכפוף לכך שהתמורה מהמכירה תוחזק אצל הנאמן עד תום
          תקופת השנתיים. לעומת זאת, אם זאת עסקת סקנדרי שבעלת המניות או האופציות
          בוחרת (אך אינה מחוייבת) למכור את המניות שלה לפני שחלפו שנתיים ממועד
          הענקת האופציות, אז החברה אינה יכולה לקבל ruling מסוג זה, והתמורה
          ממכירת המניות תחוייב במס פירותי (גבוה יותר ממס רווחי הון) בהתאם למדרגת
          המס השולי של העובדת.
        </p>
        <h3>
          חשוב לציין: האמור כאן הוא רק על קצה המזלג. היבטי המס עשויים להיות
          מורכבים יותר ולהשתנות משמעותית עקב תנאי עסקת המכירה או תנאים אישיים של
          העובדת בעלת המניות או האופציות. חשוב ומומלץ לקבל ייעוץ מס אישי במקרים
          כאלו, שיהיה מבוסס על תנאי העסקה ועל הנסיבות האישיות שלכם.
        </h3>
        <p>
          להרחבה נוספת בענייני מיסוי ואופציות, קראו את{" "}
          <a target="_blank" rel="noopener noreferrer" href="https://blog.equitybee.com/he/%d7%9e%d7%99%d7%a1%d7%99%d7%9d-%d7%95%d7%90%d7%95%d7%a4%d7%a6%d7%99%d7%95%d7%aa-%d7%94%d7%9e%d7%93%d7%a8%d7%99%d7%9a-%d7%94%d7%9e%d7%9c%d7%90/">
            המדריך
          </a>{" "}
          שפרסמנו בנושא.
        </p>
      </AccordionItem>
    </Accordion>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      