import React from "react"
import { LocalizedLink } from "gatsby-theme-i18n"

import * as styles from "./styles.module.scss"

export default function NarrowButton({ children, to, ...props }) {
  if (to) {
    return (
      <LocalizedLink className={styles.narrowButton} to={to}>
        {children}
      </LocalizedLink>
    )
  }

  return (
    <button className={styles.narrowButton} {...props}>
      {children}
    </button>
  )
}
