import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Accordion, { AccordionItem } from "/src/components/Accordion";
import * as styles from "../styles.module.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function FaqLiquidityEvent({
  groupId = "",
  hash
}) {
  const compareWithHash = id => hash.includes(id);

  return <Accordion size="md" groupId={groupId} className={`p-4 ${styles.faq}`}>
      <AccordionItem // #28
    groupId={groupId} id="what-is-a-secondary-deal" open={compareWithHash("what-is-a-secondary-deal")} heading=" מה זה עסקת Secondary?">
        <p>
          עסקת סקנדרי (secondary) היא אירוע רכישה משנית של מניות, כלומר אירוע בו
          מניות בחברה מחליפות ידיים מבלי שיכנס כסף לקופת החברה (כלומר הכסף יגיע
          לבעלי מניות קיימים בחברה בתמורה למניות שלהם). זאת בניגוד לסבבי גיוס
          הון ״רגילים״ בהם משקיעים, כמו קרנות הון סיכון, מזרימים כסף לקופת החברה
          בתמורה לקבלת מניות ולרוב יחד עם דילול האחזקות של בעלי המניות הנוכחיים.
        </p>
        <p>
          בשנים האחרונות אנחנו רואים יותר ויותר עסקאות סקנדרי בסטארטאפים ישראלים
          צומחים. ככל ששוק הסטארטאפים הישראלים מתבגר ומבשיל מתרחשות יותר עסקאות
          סקנדרי שבמקרים רבים הן כלי שנותן ליזמים, הנהלות ולעובדים את הרוגע
          הפיננסי המאפשר להם להמשיך לבנות את החברה - עד שיתרחש אירוע נזילות גדול
          כמו הנפקה או מכירה. ישנם גם גופי השקעות, כמו קרנות Private Equity,
          שמתמקדות בסוג עסקאות זה. גם החברה עצמה יכולה לרכוש את המניות שלה
          בעסקאות המכונות buy back.
        </p>
      </AccordionItem>
      <AccordionItem // #29
    groupId={groupId} id="scandal-in-the-company" open={compareWithHash("scandal-in-the-company")} heading="מה קורה לאופציות של עובדים בהווה ועובדי עבר שמימשו אותן במקרה של סקנדרי בחברה?">
        <p>
          החברה עשויה להציע לכם כעובדים בעלי אופציות שהבשילו או עובדים לשעבר
          שמימשו את האופציות שלהם להשתתף בעסקת סקנדרי. בתרחיש כזה יתאפשר לכם
          למכור עד שיעור מסויים מהאחזקות שלכם שיוגדר בדרך כלל על ידי החברה. זוהי
          הצעת רכישה במחיר מסויים למניה.
        </p>
      </AccordionItem>
      <AccordionItem // #30
    groupId={groupId} id="participate-in-the-secondary-transaction" open={compareWithHash("participate-in-the-secondary-transaction")} heading="האם כל בעלי האופציות והמניות יכולים להשתתף בעסקת Secondary?">
        <p>
          לא כל בעלי האופציות והמניות יכולים להשתתף בכל עסקת Secondary וזה משתנה
          בהתאם להגדרת סוג העסקה, דרישות של הרוכש או העדפה של החברה. למשל, יש
          עסקאות Secondary שמתקיימות לפני הנפקת החברה, כחלק מהליך ההכנה להנפקה,
          ובמקרים כאלו בדרך כלל כל בעלי המניות יוכלו להשתתף ובתנאים שווים. לעומת
          זאת, ישנן עסקאות שנעשות במהלך חיי החברה כחברה פרטית והן יוגדרו לפי
          שיקולים שונים. למשל, החברה עשויה להציע את הסקנדרי רק לעובדים שעדיין
          מועסקים בחברה. לעיתים, בגלל דרישה של הרוכש יציעו את העסקה רק לבעלי
          מניות מסוימים, כדי לא להתמודד ב-Secondary עם כמות גדולה של משקיעים
          קטנים.
        </p>
      </AccordionItem>
      <AccordionItem // #31
    groupId={groupId} id="considerations-for-participating" open={compareWithHash("considerations-for-participating")} heading=" מה השיקולים האם להשתתף בסקנדרי?">
        <p>
          ההתלבטות היא למעשה בין אירוע נזילות מובטח ובתנאים ידועים בטווח הזמן
          המיידי, לעומת אירוע נזילות תיאורטי, שאינו מובטח ותנאיו אינם ידועים,
          בעתיד. לכן, אם אתם מאמינים שהחברה תמשיך לצמוח והשווי שלה עתיד לעלות -
          זה יכול להיות שיקול לא להשתתף במכירה ולהמתין לאירוע נזילות עתידי שאתם
          מקווים שיהיה במחיר גבוה יותר.
        </p>
        <p>
          בנוסף, ישנן נסיבות אישיות שמשפיעות על קבלת ההחלטות של כל אחד ואחת -
          למשל, האם אתם זקוקים לסכום הכסף שתוכלו לקבל מהמכירה בתקופה הקרובה?
          שאלה נוספת קשורה לתוכניות העתידיות שלכם בחברה - האם אתם מתכננים להמשיך
          לעבוד בה או שאתם מתכננים לעזוב בקרוב? ואם אתם מתכננים לעזוב - האם
          תכננתם לממש את האופציות שלכם למניות?
        </p>
        <p>
          שיקול נוסף שעשוי להשפיע על ההחלטה שלכם הוא סוגיית המיסוי - אם אתם
          נמצאים בטווח של השנתיים הראשונות להענקת האופציות ותבחרו למכור אותן
          במסגרת סקנדרי, אתם עתידים לשלם שיעור מס גבוה יחסית - שיעור המס שאתם
          משלמים על ההכנסה שלכם ממשכורת (הגבוה משיעור מס רווחי הון, שתשלמו אם
          תמכרו את המניות לאחר חלוף השנתיים). בשורה התחתונה, אין תשובה אחת
          שנכונה לכולם ואתם צריכים לבחון את ההצעה בעיניים שלכם - הן מבחינת סיכון
          מול סיכוי - רווח וודאי היום לעומת רווח גבוה אך תיאורטי בעתיד בעתיד והן
          מבחינת ההעדפות האישיות שלכם והצרכים הפיננסיים שלכם - עכשיו ובעתיד.
        </p>
        <p>
          בכל מקרה, כמו תמיד, נסו לוודא שיש בידכם את מלוא המידע הרלוונטי לעסקה
          כדי לשקול את ההצעה.
        </p>
      </AccordionItem>
    </Accordion>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      