import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function Disclaimer() {
  return <p>
      <strong>Disclaimer - לתשומת לבך: </strong>
      כל המוצג במדריך זה הינו מידע כללי וחלקי בלבד. הסכם האופציות שלך ו/או תכנית
      האופציות אליה כפוף הסכם האופציות שלך, עשויים לכלול הוראות שונות מהמוצג במדריך
      זה. המידע המוצג במדריך עשוי שלא לכלול את כל ההיבטים הרלוונטיים בקשר עם קבלת
      החלטה בקשר עם האופציות או המניות שלך וכמו כן, המדריך אינו מביא בחשבון מטרות
      ספציפיות או מצב כלכלי של אדם זה או אחר. עלייך לבדוק את הוראות הסכם האופציות
      ותכנית האופציות שלך וכן לבחון את הנסיבות האישיות שלך, לרבות באמצעות התייעצות
      עם גורם מקצועי המייעץ לך באופן אישי, לפני קבלת כל החלטה הנוגעת לאופציות או
      למניות שלך, לרבות החלטה בדבר אופן מימוש האופציות ומימון עלות המימוש. אין לראות
      באמור במדריך ייעוץ או שיווק השקעות, או ייעוץ בענייני מיסוי. אין לראות באמור
      במדריך הצעה, בקשה או שידול להתקשר עם אקוויטיבי טכנולוגיות בע"מ (Equitybee)
      או כל גורם קשור אליה בהסכם הנוגע למימוש האופציות שלך ו-Equitybee אינה אחראית
      לתוצאותיהן של החלטות שיתקבלו בהתבסס על המוצג במדריך זה.
    </p>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      