import React, { useRef } from "react"
import classNames from "classnames"

import useWindowSize from "/src/hooks/useWindowSize"
import BtnClose from "/src/components/buttons/BtnClose"

import * as styles from "./styles.module.scss"

export default function Popup({ children, className, closePopup }) {
  const popupRef = useRef(null)
  const popupContentRef = useRef(null)

  const windowHeight = useWindowSize().height
  const popupContentHeight = popupContentRef.current?.clientHeight || 0
  const popupPaddingY = popupRef.current
    ? parseInt(getComputedStyle(popupRef.current).paddingTop) +
      parseInt(getComputedStyle(popupRef.current).paddingBottom)
    : 0

  const classes = classNames(className, {
    [styles.popupWrapper]: true,
    [styles.withScroll]: popupContentHeight + popupPaddingY >= windowHeight,
  })

  return (
    <div className={classes}>
      <div className={styles.popup} ref={popupRef}>
        <BtnClose onClick={closePopup} />

        <div className={styles.area}>
          <div ref={popupContentRef}>{children}</div>
        </div>
      </div>
    </div>
  )
}
