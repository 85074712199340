import React from "react"

import Popup from "../Popup"
import RegistrationForm from "./RegistrationForm"

export default function RegistrationPopup({
  isRegistrationPopupOpen,
  closePopup,
}) {
  if (!isRegistrationPopupOpen) {
    return null
  }

  return (
    <Popup closePopup={closePopup}>
      <h2 className="mb-2">
        אנחנו מתכננים לפרסם מדריכים נוספים מבית Equitybee על אופציות עובדים
        בסטארטאפים.
      </h2>
      <h3 className="mb-4"> רוצים שנעדכן אתכם כשהם ייצאו? הירשמו:</h3>

      <RegistrationForm callbackOnSuccess={closePopup} />
    </Popup>
  )
}
