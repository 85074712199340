import Accordion, { AccordionItem } from "/src/components/Accordion";
import pdf_BeforeLeaveStartup from "/src/docs/כל מה שאתם צריכים לעשות לפני שאתם עוזבים סטארטאפ.pdf";
import Checklist from "/src/components/lists/Checklist";
import Faq from "../../../../src/pages_content/leaving-startup/faq.mdx";
import Disclaimer from "../../../../src/pages_content/components/Disclaimer";
import * as styles from "../../../../src/pages_content/leaving-startup/styles.module.scss";
import * as React from 'react';
export default {
  Accordion,
  AccordionItem,
  pdf_BeforeLeaveStartup,
  Checklist,
  Faq,
  Disclaimer,
  styles,
  React
};