import Accordion, { AccordionItem } from "/src/components/Accordion";
import FaqIpo from "../../../../src/pages_content/liquidity-event/faq/he/FaqIpo.mdx";
import FaqMa from "../../../../src/pages_content/liquidity-event/faq/he/FaqMa.mdx";
import FaqSecondary from "../../../../src/pages_content/liquidity-event/faq/he/FaqSecondary.mdx";
import * as styles from "../../../../src/pages_content/liquidity-event/styles.module.scss";
import * as React from 'react';
export default {
  Accordion,
  AccordionItem,
  FaqIpo,
  FaqMa,
  FaqSecondary,
  styles,
  React
};