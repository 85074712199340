import React, { useState } from "react"
import classNames from "classnames"

import validateEmail from "/src/helpers/emailValidator"

export default function RegistrationForm({ callbackOnSuccess }) {
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [isConfirmed, setIsConfirmed] = useState(false)

  const [wasValidated, setWasValidated] = useState(false)
  const [isNameValid, setIsNameValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isRequestError, setIsRequestError] = useState(false)

  const handleChangeName = e => {
    setFullName(e.target.value)
    setIsNameValid(Boolean(e.target.value))
  }

  const handleChangeEmail = e => {
    setEmail(e.target.value)
    setIsEmailValid(validateEmail(e.target.value))
  }

  const handleConfirm = () => setIsConfirmed(!isConfirmed)

  const handleForm = async event => {
    event.preventDefault()

    setWasValidated(true)
    setIsNameValid(Boolean(fullName))
    setIsEmailValid(validateEmail(email))

    if (isNameValid && isEmailValid && isConfirmed) {
      const url = `${process.env.GATSBY_GATEWAY_API}/general/guide-lead`
      const data = {
        fullName,
        email,
      }

      try {
        setIsRequestError(false)
        setIsDataLoading(true)

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(data),
        })

        if (!response.ok) {
          const responseData = await response.json()

          const isEmailExisted =
            response?.status === 500 &&
            responseData?.data.includes("duplicate unique key")

          if (!isEmailExisted) {
            const error =
              (responseData && responseData.message) || response.statusText

            return Promise.reject(error)
          }

          // if email already existed - just close popup
          callbackOnSuccess()
        }

        callbackOnSuccess()
      } catch (error) {
        setIsRequestError(true)
        console.error("Error: ", error)
      } finally {
        setIsDataLoading(false)
      }
    }
  }

  const fullNameClasses = classNames("form-control form-control-lg", {
    "is-valid": wasValidated && isNameValid,
    "is-invalid": wasValidated && !isNameValid,
  })

  const emailClasses = classNames("form-control form-control-lg", {
    "is-valid": wasValidated && isEmailValid,
    "is-invalid": wasValidated && !isEmailValid,
  })

  const confirmClasses = classNames("form-check-input", {
    "is-valid": wasValidated && isConfirmed,
    "is-invalid": wasValidated && !isConfirmed,
  })

  return (
    <form>
      <div className="mb-4 ms-md-5">
        <input
          type="text"
          className={fullNameClasses}
          placeholder="שם מלא"
          value={fullName}
          onChange={handleChangeName}
          required
          disabled={isDataLoading}
        />
      </div>
      <div className="mb-4 ms-md-5">
        <input
          type="text"
          className={emailClasses}
          placeholder="אימייל"
          value={email}
          onChange={handleChangeEmail}
          required
          disabled={isDataLoading}
        />
      </div>
      <div className="mb-4 form-check">
        <input
          type="checkbox"
          className={confirmClasses}
          id="confirmation"
          checked={isConfirmed}
          onChange={handleConfirm}
          required
          disabled={isDataLoading}
        />
        <label className="form-check-label" htmlFor="confirmation">
          באמצעות מילוי פרטים אלה הריני לאשר בזאת את הסכמתי לקבל מאקוויטי בי
          טכנולוגיות בע"מ דיוור ישיר באמצעות הודעת דואר אלקטרוני בנוגע לחלקים
          נוספים של מדריך זה שיפורסמו בעתיד ולהצהיר כי אין ולא תהיה לי כל טענה
          או תביעה כנגד אקוויטי בי או מי מטעמה בגין קבלת הדיוור כאמור.
        </label>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-4">
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleForm}
            disabled={isDataLoading}
          >
            הרשמה
          </button>
        </div>
      </div>

      {isRequestError && (
        <p className="mt-4 px-2 alert-danger rounded-2">Something went wrong</p>
      )}
    </form>
  )
}
