import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Accordion, { AccordionItem } from "/src/components/Accordion";
import * as styles from "./styles.module.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function Faq({
  groupId = ""
}) {
  return <Accordion size="md" groupId={groupId} className={`p-4 ${styles.faq}`}>
      <AccordionItem groupId={groupId} id="faq-options" heading="מה זה אופציות?">
        <p>
          אופציה היא הזכות שניתנת לעובדים לרכוש מניות בחברה במחיר קבוע מראש
          (הידוע כ”מחיר המימוש”). אולם, עובדים אינם מחוייבים לממש את האופציות
          שלהם. לרוב, החברה תאגד את האופציות בחוזים, כאשר כל חוזה מכיל מספר ידוע
          מראש של אופציות שניתנות לכל עובד/ת ומבשילות לאורך זמן שנקבע מראש.
        </p>{" "}
      </AccordionItem>
      <AccordionItem groupId={groupId} id="faq-leave-company" heading="מה קורה לאופציות שלי שהבשילו כשאני עוזב/ת את החברה?">
        <p>
          אם אתם עוזבים את החברה ויש בידיכם אופציות שהבשילו (כלומר עברו את תקופת
          ה-vesting שלהן), יש לכם תקופת זמן מוגבלת לממש אותן. בדרך כלל תקופת
          ההחלטה נמשכת 90 יום, אך יכולה להיות גם באורך שונה, והיא קבועה לכלל
          העובדים בחברה לפי תוכנית האופציות של הסטארטאפ. זוהי תקופה די קצרה שבה
          עליכם לקבל החלטה כלכלית שיכולה להיות משמעותית למצבכם הכלכלי בהווה
          (מבחינת הסכום שתדרשו להוציא היום מהכיס שלכם עבור עלות המימוש) ובעתיד
          (אקזיט = רווח, כשלון = הפסד כסף). עובדים רבים מחליטים שלא לממש את
          האופציות ומאבדים אותן. במקרים בהם החברה מצליחה ומגיעה לאקזיט, העובדים
          שעזבו מבלי לממש מפסידים סכום משמעותי.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="immature-options" heading="מה קורה לאופציות שלי שלא הבשילו כשאני עוזב/ת חברה?">
        <p>
          אופציות שהוענקו לכם כעובדים אך עוד לא הבשילו (unvested) חוזרות לחברה
          כאילו הן לא הוענקו לכם מעולם. העובדה שאופציות שלא הבשילו חוזרות לחברה
          לא צריכה להפתיע אתכם – כל רעיון ההבשלה משמעותו שעובדים צריכים לעבוד
          בחברה פרק זמן מסוים כדי “להרוויח” את האופציות ולקבל תגמול על העבודה
          הקשה שלכם שתרמה לצמיחת הסטארטאפ. לכן, למרות שהאופציות הוענקו לכם במועד
          ההעסקה (או במועד אחר), כל עוד הן לא הבשילו, הן לא באמת שלכם.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="options-cost" heading="כמה יעלה לי לרכוש את האופציות שלי שהבשילו?">
        <p>
          אופציה היא זכות לרכוש מניה בעתיד במחיר מוסכם מראש – שנקרא מחיר המימוש
          (strike price או exercise price) – אשר נקבע ביום הקצאת האופציה. כלומר,
          עוד לפני שהצטרפתם לחברה אתם כבר יודעים מה יהיה המחיר שתשלמו על המימוש
          שלהן כשתעזבו. בדרך כלל האופציות יוענקו במחיר מימוש המשקף את שווי המניה
          הזמין האחרון. כדי לחשב את עלות רכישת האופציות, עליכם להכפיל את מספר
          האופציות שלכם שהבשילו במחיר המימוש. שימו לב – זהו אינו הרווח שלכם
          במקרה של אקזיט (ראו תשובה לשאלה באיזה מקרים אני יכול/ה להרוויח אחרי
          שעזבתי חברה?/כמה ארוויח ממימוש האופציות שלי למניות?)
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="information-regarding-value" heading="האם החברה/מעסיק מחוייבים לתת לי מידע בנוגע לשווי האופציות שלי?">
        <p>
          אמ;לק: לא. אבל יש דרכים אחרות להעריך את שווי חבילת האופציות שהוצעה
          לכם. בעולם אידיאלי, בו הכל שקוף וידוע, החברה הייתה משתפת איתכם כבעלי
          מניות בחברה מידע בנוגע להערכת השווי האחרונה של החברה וכמות המניות אשר
          הוקצו עד היום בחברה סך הכל. חשבון פשוט היה מגלה איזה אחוז מן החברה
          מציעים לכם, ומה השווי התיאורטי של אותו שיעור. ואולם, במציאות רוב
          החברות אינן ששות לשתף את תנאי הגיוס האחרון וכמות המניות שהוקצו בחברה –
          והן אינן מחוייבות בכך. לכן, צריך לרוב להעזר בשאלות אחרות כדי להעריך את
          שווי ההצעה שקיבלתם. רוב החברות למשל לא מתנגדות להשיב לשאלה ׳מה היה
          שווי כל מניה בסבב גיוס ההון האחרון של החברה׳. המידע הזה לא מאפשר
          למועמדים לדעת כמה מניות יש לחברה או מה שוויה, אך הוא מספיק על מנת
          שתוכלו להעריך את שווי חבילת האופציות שהוצעה לכם לפי הפער בין מחיר
          המימוש שלכם למחיר המניה בסבב האחרון (תוכלו גם להעזר במחשבון שלנו על
          מנת לברר זאת). קחו בחשבון שבגלל ההבדל בין סוגי המניות השונים והזכויות
          שהן מקנות לבעליהן, לפעמים מחיר המניה לא משקף את השווי האמיתי שתקבלו
          במקרה של אקזיט (הסבר נוסף תוכלו לקבל תחת סעיף הסיכונים). מידע נוסף
          תוכלו למצוא במדריך{" "}
          <a href="https://blog.equitybee.com/il/%D7%9B%D7%9A-%D7%AA%D7%A0%D7%94%D7%9C%D7%95-%D7%9E%D7%A9%D7%90-%D7%95%D7%9E%D7%AA%D7%9F-%D7%A2%D7%9C-%D7%97%D7%91%D7%99%D7%9C%D7%AA-%D7%94%D7%90%D7%95%D7%A4%D7%A6%D7%99%D7%95%D7%AA-%D7%A9%D7%9C%D7%9B/">
            שפירסמנו על ניהול מו״מ על חבילת האופציות שלכם
          </a>
          .
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="exercising-my-options" heading="איך לקבל החלטה לגבי מימוש האופציות שלי? האם כדאי לי לממש אותן?">
        <p>
          הדבר הראשון שאתם צריכים להחליט זה האם אתם ספקנים, כלומר לא מאמינים
          בחברה שאתם עוזבים ולכן לא מוכנים להשקיע בה באמצעות מימוש האופציות; או
          האם אתם מאמינים בסיכויי ההצלחה של החברה בעתיד למרות שקיבלתם החלטה
          לעזוב אותה משיקולים שונים. ההחלטה הזו היא מן הסתם לא פשוטה, ויש לה
          השלכות על העתיד הכלכלי שלכם. אבל אם ביליתם מספר שנים בחברה אתם אמורים
          להכיר את המוצר שלה, השוק, התחרות, צוות ההנהלה, ואולי אפילו נחשפתם
          לביצועים הפיננסיים שלה ולתחזיות הצמיחה. על סמך אלה אתם יודעים האם אתם
          מאמינים בחברה ומעוניינים לממש את האופציות שלכם בה. אם המסקנה שמה אתכם
          בצד ה״ספקנים״, הגיע הזמן להסתכל קדימה לעבר העבודה החדשה – ולהיפרד
          מהאופציות שלכם בחברה שאתם עוזבים. אנחנו ממליצים להביא בחשבון גם את
          הסיכונים הכרוכים במימוש חבילת האופציות שלכם. הקדשנו לכך סעיף. לעומת
          זאת, אם אתם מאמינים בחברה וצריכים עזרה עם עלות המימוש, או אם אתם
          שייכים לאלה שאין להם מושג ואתם רוצים ללמוד קצת על הנושא, אנחנו פה
          בשביל לעזור לכם.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="involved-risks" heading="מה הסיכונים הכרוכים במימוש אופציות עובדים?">
        <p>
          אם תחליטו לממש את חבילת האופציות שלכם תוכלו להתעשר, אולי אפילו בגדול.
          אבל קחו בחשבון שהמימוש כרוך במספר סיכונים שחשוב להיות מודעים אליהם:
        </p>
        <ol>
          <li>
            אתם צריכים להוציא עכשיו כסף ולוותר על הנזילות שלו – בשביל לממש את
            האופציות תצטרכו להוציא כסף מהכיס, ובתמורה תקבלו מניות בסטאטרטאפ. זאת
            אומרת שאתם צריכים להפוך הון שכיום הוא נזיל לנכס שלא תוכלו לסחור בו
            עד לאירוע נזילות בחברה (שיכול להתרחש בעוד שנים רבות, ובכל מקרה במועד
            בלתי ידוע).
          </li>
          <li>
            אתם עלולים להפסיד כסף – במקרה והחברה תסגר, או שהיא לא תמכר או תנפיק
            אתם עלולים לא להפגש לעולם עם הכסף שהוצאתם. כמו כן, יכול להיות שהחברה
            תרכש בשווי מניה נמוך בהשוואה לשווי בו אתם מימשתם את האופציות שלכם.
            בכל התרחישים הללו תפסידו כסף.
          </li>
          <li>
            למשקיעים אחרים יכולה להיות עדיפות עליכם במקרה של אקזיט – עוגת בעלי
            המניות של חברה מורכבת מכמה סוגי מניות ולא תוכלו לדעת מה התנאים
            שהובטחו לבעלי סוגי המניות השונים. לרוב אופציות שעובדים מממשים הופכות
            למניות רגילות (common stock). אולם, ישנם משקיעים בעלי מניות בחברה
            שיכולים לקבל מניות בעלות עדיפות ( preferred stock) על המניות שלכם.
            המשמעות היא שבאירוע של אקזיט בעלי מניות אלה יקבלו עדיפות וקדימות
            ולעיתים תנאים מועדפים. במצבים אלה התשלומים לבעלי מניות רגילות (אתם,
            העובדים) יכולים לקבל סכום קטן יחסית למה שניתן היה לצפות, גם באקזיטים
            גדולים.
          </li>
          <li>
            עד שתוכלו למכור את המניות שלכם, שווי המניה עלול לרדת – במקרה של
            הנפקה נקבעת על ידי החברה תקופת חסימה (lock-up period) במהלכה בעלי
            מניות לא יכולים למכור אותן בבורסה. בתקופה הזו הכסף שלכם עדיין לא
            נזיל, ולעיתים עד שתחלוף תקופת החסימה שווי המניה ירד בהשוואה למחיר
            שלה בהנפקה.
          </li>
        </ol>
        <p>
          אנחנו מבינים את הסיכונים עבורכם הכרוכים במימוש חבילת אופציות. לכן
          פיתחנו פתרון שמאפשר לכם להרוויח כשהחברה מצליחה, בלי להיות חשופים
          להפסדים.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="options-worth" heading="כמה שוות האופציות שלי?">
        <p>
          כאמור, כאשר תממשו את האופציות שלכם תדרשו לשלם עליהן לפי מחיר המימוש
          שנקבע כשהקצו לכם אותן. אולם, יתכן שהאופציות שלכם שוות יותר מעלות
          המימוש שלהן, מאחר והחברה צמחה והצליחה מאז שהצטרפתם אליה. כל עוד החברה
          לא עשתה אקזיט, כלומר נרכשה או הנפיקה, לא תוכלו לדעת בוודאות מה השווי
          הנוכחי של האופציות שלכם וכמה באמת תקבלו עבורן. לכן ישנו אלמנט של סיכון
          ברכישה שלהן. אינדיקציה טובה להעריך את המצב הנוכחי היא לברר מה שווי
          המניה שהחברה קיבלה בסבב הגיוס האחרון שלה. לפעמים, אם קיבלתם יותר
          מחבילת אופציות אחת במשך התקופה בה הועסקתם בחברה, תוכלו להשוות את
          השינוי במחיר המימוש בין חבילות האופציות שהוקצו לכם ולבדוק האם הוא עלה
          (לרוב, עלייה כזו תעיד על צמיחה בשווי החברה). עוד אינדיקציה תוכלו לקבל
          מתוך היכרות שלכם עם הנכסים של החברה – האם היא גייסה סכומי כסף
          משמעותיים? האם מספר הלקוחות שלה צמח? וכו׳
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="turn-into-stocks" heading="איך אפשר לממש אופציות ולהפוך אותן למניות?">
        <p>
          ניתן לממש רק אופציות שהבשילו. לרוב, תקופת ההבשלה (vesting) נפרסת על
          פני ארבע שנים. בתום השנה הראשונה מגיע מועד שנקרא cliff (צוק) שלאחריו
          מבשילות רבע מהאופציות. לאחר מכן, בשלוש השנים הבאות, יתר האופציות
          מבשילות לפי חלקן היחסי בכל חודש או רבעון. כאשר אתם מחליטים לעזוב חברה
          בה עבדתם, ניתנת לכם אפשרות לרכוש את האופציות שהבשילו ולממש אותן למניות
          בדרך כלל בתוך תקופה באורך 90 יום. הסכום שתדרשו לשלם נקבע לפי שווי
          המימוש של האופציות שנקבע כשקיבלתם אותן. אם החלטתם אכן לממש את האופציות
          ולהפוך אותן למניות, תצטרכו לשלם לחברה את מלוא הסכום במהלך התקופה הזו.
          אנחנו יודעים שלעיתים מדובר בסכומי כסף משמעותיים עבורכם, ולכן פיתחנו
          פתרון שיעזור לכם לממש את האופציות שלכם בלי לקחת סיכון ובלי להוציא כסף
          מהכיס. לאחר מימוש האופציות תהפכו לבעלי מניות בחברה ותהיו זכאים לרווחים
          עתידיים שיקבלו בעלי המניות, למשל תשלום במקרה של אקזיט על פי חלקכם
          היחסי או חלוקת דיבידנד. אולם, קחו בחשבון שאם החברה תמשיך לגייס כסף
          ולהכניס בעלי מניות חדשים, חלקכם היחסי עשוי להצטמצם בעתיד. שימו לב
          שאופציות שלא תממשו יאבדו לעד.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="taxation-of-options" heading="מה המיסוי על מימוש אופציות?">
        <p>
          כאשר אתם עוזבים חברה ובוחרים לממש את האופציות שלכם, לא מתרחש אירוע מס.
          סעיף 102 לפקודת מס הכנסה, המסדיר את מתן האופציות, דוחה את כל תשלום המס
          ליום האקזיט. אינכם צריכים לשלם מיסים כלל ביום הענקת האופציות או במועדי
          ההבשלה. אירוע המס יתרחש רק ביום האקזיט בו תמכרו את המניות. אם האופציות
          או המניות הוחזקו לפחות שנתיים אצל נאמן – תחוייבו במס רווחי הון בלבד.
          ברוב המקרים מדובר במס בשיעור של 25% הנמוך משמעותית ממס פירותי אשר אותו
          משלמים על שכר. שימו לב שהיה ותמכרו את המניות או תוציאו אותן מאחזקת
          הנאמן אפילו ללא מכירה לפני תום השנתיים, תחוייבו במס פירותי מלא שיהיה
          גבוה משמעותית מ-25%. למידע נוסף, מוזמנים לקרוא את הפוסטים בבלוג שלנו
          בנושא סעיף 102 ומיסוי אופציות.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="article-102" heading="מה זה סעיף 102 ולמה הוא חשוב?">
        <p>
          סעיף 102 לפקודת מס הכנסה מסדיר את הקצאת האופציות לעובדים בסטארט-אפים
          ומגדיר הטבת מס לעובדים באירוע של אקזיט. סעיף 102 קובע את הפרטים הבאים:
          > ניתן להעניק אופציות רק לעובדי החברה > לרוב ינתנו לעובד 90 יום למימוש
          האופציות לאחר עזיבת החברה. אם החברה מאשרת את הארכת התקופה, האופציות לא
          יוכרו בסעיף 102 וישולם עליהן שיעור מס גבוה. > האופציות ממוסות במס
          רווחי הון ביום האקזיט – לרוב בשיעור של 25%. זוהי הטבה משמעותית לעובדים
          בסטארטאפים שלרוב שיעור המס השולי שהם משלמים על ההכנסה שלהם הוא גבוה
          יותר. > ביום מימוש האופציה למניה אין אירוע מס. > האופציות חייבות להיות
          מופקדות לפחות שנתיים מרגע הקצאת האופציות אצל נאמן (עבור תקופה קצרה
          יותר הן לא יהיו זכאיות למס רווחי הון). למידע נוסף בנושא סעיף 102
          לפקודת מס הכנסה קיראו את הפוסט בנושא בבלוג שלנו.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="my-stock-options" heading="באיזה מקרים אני יכול/ה להרוויח אחרי שעזבתי חברה?/כמה ארוויח ממימוש האופציות שלי למניות?">
        <p>
          pבמקרה שבחרתם לממש את האופציות שלכם למניות תהיה לכם הזדמנות להרוויח
          באירועי נזילות עתידיים בחברה (מכירה או הנפקה). אם בתקופה שעברה מאז
          שהצטרפתם לחברה ועד לאקזיט החברה צמחה וגייסה עוד כסף – ברוב המקרים
          המשמעות היא ששווי החברה עלה וכך גם שווי המניה שלה. במילים אחרות, הרווח
          שלכם הוא הפער בין מחיר המימוש לבין מחיר המניה ביום האקזיט. אם המחיר
          עלה – הרווחתם. אם נעזרתם בקהילת המשקיעים שלנו כדי לממש את האופציות
          שלכם, אז למעשה הם לקחו את הסיכון עבורכם ואתם מרוויחים מהצלחת החברה.
          עוד דרך בה אתם יכולים להרוויח לאחר עזיבת החברה כבעלי מניות שבחרו לממש
          את האופציות שלהם, היא במקרים של חלוקת דיבידנד לבעלי המניות או עסקאות
          סקנדרי (למשל, סבבי גיוס הון בהם מאפשרים לעובדים למכור את האחזקות
          שלהם).
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="event-of-an-exit" heading="מה יקרה לאופציות שלי במקרה של אקזיט?">
        <p>
          מזל טוב! הסטארטאפ שפעם עבדתם בו, והיום לאחר מימוש האופציות שלכם אתם
          בעלי מניות בו, נרכש על ידי חברה אחרת. מה קורה עכשיו ומתי תיפגשו עם
          הכסף שמגיע לכם? קודם כל, חשוב להבין מה סוג העסקה בה החברה נרכשה. אם
          מדובר בעסקת מזומן, זה די פשוט – תקבלו כסף על פי חלקכם היחסי בחלוקת
          המניות הנוכחית בחברה. אם זו עסקת מניות, תקבלו במקום המניה שלכם
          בסטארטאפ בו עבדתם מניה בחברה הרוכשת, לפי יחס המרה שייקבע בהסכם הרכישה.
          אם זו חברה ציבורית, תקבלו מניה בחברה הציבורית ודי מהר תוכלו למכור אותה
          בבורסה ולממש אותה. אם זו חברה פרטית, תצטרכו להמשיך להמתין לאקזיט שלה.
          עוד מידע בנוגע לאופציות שלכם ומה שעתיד לקרות להן באקזיט תוכלו למצוא
          במדריך שלנו שמתייחס לאופציות עובדים באירועי נזילות.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="what-can-i-do-with-my-options" heading="מה אני יכול/ה לעשות עם האופציות שלי עד האקזיט?">
        <p>
          החלטתם לרכוש את האופציות שלכם כשעזבתם את הסטארטאפ שעבדתם בו. עכשיו,
          הניחו אותן בצד והמתינו בסבלנות לאירוע נזילות בחברה שיאפשר לכם להרוויח
          מהן, למשל רכישה או הנפקה. אם החלטתם להמיר את האופציות שלכם למניות,
          מכיוון שמדובר בחברה פרטית, ברוב המקרים לא תהיה לכם כל אפשרות לסחור
          במניות. לכן, תצטרכו להמתין ליום שהחברה תימכר כדי לקבל עבורן כסף.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="allowed-to-sell-my-shares" heading="האם מותר לי למכור את המניות שלי אחרי שעזבתי ומימשתי את האופציות?">
        <p>
          אם תקבלו אישור מהחברה שעבדתם בה והפכתם לבעלי מניות בה לאחר מימוש
          האופציות – תוכלו למכור את המניות שלכם למשקיעים שיהיו מעוניינים בכך.
          בכל מקרה, תצטרכו לברר עם החברה מה המדיניות המקובלת בה.
        </p>
      </AccordionItem>
      <AccordionItem groupId={groupId} id="a-lot-of-money" heading="איך אפשר לדעת אם לחברה שאני עובד/ת בה יש סיכוי גדול לעשות אקזיט ושהאופציות שלי יהיו שוות הרבה כסף?">
        <p>
          סטארטאפים הם חברות פרטיות. ככאלה, לא חלה עליהן חובת דיווח בנוגע
          לביצועי החברה ומידע פיננסי אחר. ולמעשה לכם כעובדים שהם גם בעלי אופציות
          בחברה אין גישה למידע בנוגע לשווי הנוכחי של האופציות שברשותכם וכמה
          משקיעים מוכנים לשלם עבורן בעתיד. לכן, השאלה היותר מעניינת מבחינתכם היא
          כמה יהיו שוות האופציות שלכם באקזיט. בלתי אפשרי לדעת כמה חברה רוכשת או
          משקיעים בבורסה יהיו מוכנים לשלם עבור מניית החברה. אבל הנה כמה דרכים
          שיעזרו לכם להעריך האם יש סיכוי שהחברה שאתם עובדים בה תצליח ותעשה אקזיט
          משמעותי. ראשית, Equitybee מפרסמת את רשימת החברות שאופציות העובדים שלהן
          הן המבוקשות ביותר על ידי משקיעים. אם אתם עובדים בעלי אופציות באחת
          החברות המופיעות ברשימה – תוכלו לקבל אישור מהשוק שמשקיעים מאמינים בחברה
          שאתם עובדים ושיש סיכוי שהאופציות שלכם יהיו שוות הרבה כסף. אם החברה לא
          מופיעה ברשימה, זה עדיין לא אומר שהאופציות שלכם לא שוות הרבה. עוד דרך
          להעריך האם השווי של האופציות שלכם יגדל באירוע נזילות היא על בסיס
          ההיכרות שלכם עם החברה ומידע שהתפרסם במקורות מידע גלויים, למשל כתבות
          בתקשורת. נסו לבצע הערכה ולהבין האם מאז שהצטרפתם לחברה היא צמחה
          והצליחה? האם היא קיבלה אמון ממשקיעים שהשקיעו בה הון נוסף? האם נראה
          שהיא בכיוון של הצלחה? אם התשובה לשאלות הללו היא כן, יש סיכוי שהשווי של
          האופציות שלכם בעתיד יגדל וכדאי לשקול את הרכישה שלהן. אם אתם עוד
          מתלבטים, מוזמנים לפנות אלינו להתייעץ בנוגע לאפשרות לקבל מימון לרכישת
          האופציות שלכם.
        </p>
      </AccordionItem>
    </Accordion>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      